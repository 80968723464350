<template>
  <b-container fluid>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-primary card-tabs">
          
          <div class="p-0 pt-1 card-header">
            <ul class="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                <li class="pt-2 px-3"><h3 class="card-title">{{ label.name }}</h3></li>
                <li class="nav-item">
                    <a href="#custom-tabs-four-general" class="nav-link active" id="custom-tabs-four-general-tab" data-toggle="pill" role="tab" aria-controls="custom-tabs-four-general" aria-selected="true">Allgemein</a>
                </li>
                <li class="nav-item">
                    <a href="#custom-tabs-four-orders" class="nav-link" id="custom-tabs-four-orders-tab" data-toggle="pill" role="tab" aria-controls="custom-tabs-four-orders" aria-selected="true">Bestellungen</a>
                </li>
            </ul>
          </div>

          <div class="card-body">
            <div class="tab-content" id="custom-tabs-four-tabContent">
                <div class="tab-pane fade active show" id="custom-tabs-four-general" role="tabpanel" aria-labelledby="custom-tabs-four-general">
                    <General :label="label" v-if="notEmptyObject(label)" />
                </div>

                <div class="tab-pane fade" id="custom-tabs-four-orders" role="tabpanel" aria-labelledby="custom-tabs-four-orders">
                    <Orders :label="label" v-if="notEmptyObject(label)" />
                </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import General from './components/General';
import Orders from './components/Orders';

export default {
    name: "LabelDetails",
    title: "Etikettendetails",

    components: {
        General,
        Orders
    },

    data() {
        return {
            label: {},
        }
    },

    methods: {
        loadLabel() {
            this.axios
                .get("/labels/" + this.$route.params.id)
                .then((response) => {
                    this.label = response.data.data;                    
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        }
    },

    created() {
        this.loadLabel();
    },
}
</script>

<style>

</style>